import React from 'react'
import {
    BrowserRouter as Router,
    Route
} from 'react-router-dom';

import Join from './components/Join/Join';
import Chat from './components/Chat/Chat';

import './App.css';

export default function App() {
    return (
        <Router>
            <Route path="/" exact component={Join}></Route>
            <Route path="/chat" component={Chat}></Route>
        </Router>
    )
}